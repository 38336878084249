
$opacity: 87%;

$primaryBlueColor : #082568;
$backButtonColor: #686868;
$cancelButtonColor:#EC3030;
$moreButtonColor:#0E9587;
$lightYellowColor:beige;
$lightGreenColor: #009805;
$redColor: #EC3030;
$whiteColor: white;
$tableHeadingColor:#8E8E8E;
$OrangeTextcolor: #EC7B30;
$blackColor:#000000;
$disableGreyText: #929292;

$buttonBoxShadow: 0px 2px 6px rgba($color: #000000 , $alpha: 0.16);
$iconPadding: 5px 0 5px 0;
$btnHeight: 37.31px;

$RobotoRegular: 'Roboto-Regular';
$RobotoLight: 'Roboto-Light';
$RobotoBold: 'Roboto-Bold';
$RobotoMedium: 'Roboto-Medium';

$homeLeftWidth: 27vw;
$homeLeftWidth: 429px;
$homeTopMargin: 75px;
$homeRightMargin: 20px;
$homeBottomMargin: 10px;
$deviceWidth: 100vw;
$deviceHeight: 100vh;
$footerHeight: 32px;

$hexagonWidth: 185px;                         //170.8871px    163
$hexagonHeight: 200px ;                        //195px      186px
$hexagonSpacing: 16px;
$tableRowHeight: 40px;

