@import "src/_variables";

@mixin font-style($font-family, $font-size){
    font-family: $font-family,  Arial, Helvetica, sans-serif;
    font-size: $font-size;
}

/* scroll-bar style */

:host::ng-deep .ps__thumb-y {
    width: 6px !important;
    right: -2px !important;
    background-color: $primaryBlueColor !important;
}

:host::ng-deep .ps__rail-y{
    width: 1px !important;
    background-color:rgba(112,112,112,1) !important;
    right: 2px!important;
    margin-right:10px;
}
