/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "src/_mixins.scss";
@import "src/_variables.scss";
@include mat.core();

$ta-primary: mat.define-palette(mat.$grey-palette, 600, 500, 500);
$ta-secondary: mat.define-palette(mat.$teal-palette);
$ta-warn: mat.define-palette(mat.$red-palette);
$theme-primary: $ta-primary;
$theme-secondary: $ta-secondary;
$theme-warn: $ta-warn;
$theme: mat.define-light-theme($theme-primary, $theme-secondary, $theme-warn);    
@include mat.all-component-themes($theme);

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./material-icons/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url(./material-icons/MaterialIcons-Regular.woff2) format('woff2'),
      url(./material-icons/MaterialIcons-Regular.woff) format('woff'),
      url(./material-icons/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face{
    font-family: $RobotoRegular;
    src: url('assets/fonts/robotottf/Roboto-Regular.ttf');
}

@font-face{
    font-family: $RobotoLight;
    src: url('assets/fonts/robotottf/Roboto-Light.ttf');
}

@font-face{
    font-family: $RobotoMedium;
    src: url('assets/fonts/robotottf/Roboto-Medium.ttf');
}

@font-face{
    font-family: $RobotoBold;
    src: url('assets/fonts/robotottf/Roboto-Bold.ttf');
}

@font-face{
    font-family: Roboto-Bold-Italic;
    src: url('assets/fonts/robotottf/Roboto-BoldItalic.ttf');
}

@font-face{
    font-family: Roboto-Light-Italic;
    src: url('assets/fonts/robotottf/Roboto-LightItalic.ttf');
}

@font-face{
    font-family: Roboto-Medium-Italic;
    src: url('assets/fonts/robotottf/Roboto-MediumItalic.ttf');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.mat-checkbox .mat-checkbox-frame{
    background-color: white;
}
.mat-radio-button .mat-radio-outer-circle{
    background-color: white;
}

.lg-font{
    @include font-style($RobotoRegular, 18px);
}
.md-font{
    @include font-style($RobotoRegular, 16px);
}
.sm-font{
    @include font-style($RobotoRegular, 14px);
}
.xs-font{
    @include font-style($RobotoRegular, 12px);
}
.lg-bold-font{
    @include font-style($RobotoBold, 18px);
}
.md-bold-font{
    @include font-style($RobotoBold, 16px);
}
.sm-bold-font{
    @include font-style($RobotoBold, 14px);
}
.xs-bold-font{
    @include font-style($RobotoBold, 12px);
}
.xlg-medium-font{
    @include font-style($RobotoMedium, 20px);
}
.lg-medium-font{
    @include font-style($RobotoMedium, 18px);
}
.md-medium-font{
    @include font-style($RobotoMedium, 16px);
}
.sm-medium-font{
    @include font-style($RobotoMedium, 14px);
}
.xs-medium-font{
    @include font-style($RobotoMedium, 12px);
}
.mainRouterContainer{
    height: calc(#{$deviceHeight} - #{$footerHeight} - #{$homeTopMargin} - #{$homeBottomMargin});
    width: calc(#{$deviceWidth} - #{$homeLeftWidth} - #{$homeRightMargin});
    margin: $homeTopMargin $homeRightMargin $homeBottomMargin $homeLeftWidth;
    position: relative;
    border-radius: 10px;
    background-color: $lightYellowColor;
    #router{
        height: calc(100% - 55px);
        display: block; //inline-block
        width: 100%;
      
    }
}

.success, .warn, .info{
    @include font-style($RobotoMedium, 20px);
}
.success{
    color: $lightGreenColor;
}
.warn{
    color: $redColor;
}
.info{
    // @include font-style($RobotoRegular, 20px);
    color: $primaryBlueColor;
}
.full{
    height: 100%;
    width: 100%;
}
.f-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.heading{
    background-color: $primaryBlueColor;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: $whiteColor;
    @include font-style($RobotoMedium, 20px);
    display: inline-block;
    padding: 15px 20px;
}
.dashboard{
    margin: 6px;
    color: $primaryBlueColor;
    @include font-style($RobotoMedium, 20px);
}
.triangle{
    width: 0;
    height: 0;
    border-top:55px solid $primaryBlueColor;;
    border-right: 44px solid transparent;
    content: " ";
    border-bottom-left-radius: 14px;

}
.primaryFont{
    color: $blackColor;
    opacity: $opacity;
}

.outerMostDiv
{
    padding-left: 20px;
    padding-right:16px;
    padding-top: 10px;
    padding-bottom:16px;
}

::-webkit-scrollbar{
    width: 6px;

}

 ::-webkit-scrollbar-thumb{
    background-color: $primaryBlueColor;
    border-radius: 4px;
}

ls-button{
    display: inline-block;
     .narrow{
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

.required-asterisk{
    color: $redColor;
}
